import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, FormGroupList, notification } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';

import { getpromotionCodeList } from '@/redux/modules/tagbatchConfigs/tagbatchConfigs';

import './index.scss';

import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';






const required = (val) => {
    if (!val) {
        return '码序号不能为空';
    }
    return null;
};

@connect(
    state => ({

    }),
    {
        getpromotionCodeList: getpromotionCodeList.REQUEST,
    }
)
class ExtractionCodePackageTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showdata: {}
        };

    }
    componentDidMount() {

    }

    loadScript = (url, func) => {




        var head = document.head || document.getElementByTagName('head')[0];
        var script = document.createElement('script');
        script.src = url;

        script.onload = script.onreadystatechange = function () {
            if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
                func();
                script.onload = script.onreadystatechange = null;
            }
        };
        console.log(head)
        head.insertBefore(script, head[0]);
    }

    //上传
    handleSubmit2 = (data) => {



        this.props.getpromotionCodeList({
            data: data,
            callback: (data) => {

                this.setState({ showdata: data || {} })
                if (!data) {
                    notification.warning('查无此码')
                }
            }
        });
    }



    render() {
        const { handleSubmit } = this.props;
        const { showdata } = this.state;
        return (

            <div className="block" style={{ position: 'relative', display: 'block' }}>
                <p style={{ textAlign: "center", marginTop: 20, marginBottom: 0, color: '#006EFF' }}>艾希码查询</p>
                <br></br>
                <div className="form-body" >

                    <FormGroupList>
                        <FormGroupField
                            // className="form-input-invitationCode"
                            name="data"
                            label="请输入码序号"
                            component={Input}
                            validate={[required]}
                        />
                    </FormGroupList>
                    <Button style={{ marginLeft: 10 }} onClick={handleSubmit(this.handleSubmit2)}>立即查询</Button>

                </div>
                <table style={{ width: '80%', marginLeft: '10%', textAlign: 'center', border: '1px solid #ddd' }}>
                    <tbody>
                        <tr>
                            <td width='40%' style={{ borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd' }}>盖内码</td>
                            <td style={{ borderBottom: '1px solid #ddd' }}>{showdata.Code}</td>
                        </tr>
                        <tr>
                            <td width='40%' style={{ borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd' }}>批次号</td>
                            <td style={{ borderBottom: '1px solid #ddd' }}>{showdata.BatchNo}</td>
                        </tr>
                        <tr>
                            <td width='40%' style={{ borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd' }}>产品条码</td>
                            <td style={{ borderBottom: '1px solid #ddd' }}>{showdata.EAN13}</td>
                        </tr>
                        <tr>
                            <td width='40%' style={{ borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd' }}>创建时间</td>
                            <td style={{ borderBottom: '1px solid #ddd' }}>{showdata.CreateTime ? showdata.CreateTime.replace('T', ' ') : ''}</td>
                        </tr>
                        <tr>
                            <td width='40%' style={{ borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd' }}>是否新码</td>
                            <td style={{ borderBottom: '1px solid #ddd' }}>{showdata.IsNewCode == true ? 'true' : showdata.IsNewCode == false ? 'false' : ''}</td>
                        </tr>
                        <tr>
                            <td width='40%' style={{ borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd' }}>盖外码</td>
                            <td style={{ borderBottom: '1px solid #ddd' }}>{showdata.ParentCode}</td>
                        </tr>
                        <tr>
                            <td width='40%' style={{ borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd' }}>是否激活</td>
                            <td style={{ borderBottom: '1px solid #ddd' }}>{showdata.IsActive == true ? 'true' : showdata.IsActive == false ? 'false' : ''}</td>
                        </tr>
                    </tbody>
                </table>
                {/* <iframe name='ifr' id="ifr" src="http://125.68.186.77:18010/?Key=acexe5lzljopen&Code=0194920218950760&method=codeinfo_ym"></iframe> */}
            </div>




        )
    }
}


export default reduxForm({
    form: 'ExtractionCodeTableForm',
    initialValues: {

    }
})(ExtractionCodePackageTable)